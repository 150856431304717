<template>
  <div>
    <div
      class="sign-up-header"
      style="background-image: url('images/background.jpeg')"
    >
      <div class="content">
        <h1 class="mb-5">{{ $t("button.register") }}</h1>
        <p class="text-lg">
          {{ $t("form.description") }}
        </p>
      </div>
    </div>
    <CardSignUp />
  </div>
</template>
<script>
import CardSignUp from "../components/SignUp/CardSignUp.vue";

export default {
  components: {
    CardSignUp,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style></style>
