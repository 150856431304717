var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{staticClass:"card-signup header-solid h-full",staticStyle:{"max-width":"550px"},attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0 }}},[_c('div',{staticClass:"card card-container"},[_c('img',{staticClass:"profile-img-card",attrs:{"id":"profile-img","src":"images/logo-laopt.png"}}),_c('a-form',{staticClass:"form-signup",attrs:{"form":_vm.form},on:{"submit":_vm.handleRegister}},[(!_vm.successful)?_c('div',[(_vm.current === 0)?_c('div',[_c('div',{staticClass:"choice"},[_c('a-radio-group',{on:{"change":_vm.onChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('a-radio',{attrs:{"value":1}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("form.phone")))])]),_c('a-radio',{attrs:{"value":2}},[_c('strong',[_vm._v(_vm._s(_vm.$t("form.email")))])])],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',[(_vm.showPhone)?_c('div',[_c('div',{staticClass:"my-10"},[_c('label',{staticClass:"mb-10",attrs:{"for":"phone"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("form.phone")))])]),_c('vue-tel-input',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:('required|min:8|max:30'),expression:"'required|min:8|max:30'"}],staticClass:"mt-10",attrs:{"type":"text","data-vv-rules":"required","data-vv-as":"Phone","data-vv-scope":"formPhone","required":"","name":"phone"},model:{value:(_vm.vueTel.phone),callback:function ($$v) {_vm.$set(_vm.vueTel, "phone", $$v)},expression:"vueTel.phone"}},'vue-tel-input',_vm.vueTel.props,false)),_c('div',{staticStyle:{"color":"red"}},[(
                          _vm.errors.first('formPhone.phone') ==
                          'The Phone field is required.'
                        )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.requirePhone")))]):_vm._e()])],1)]):_c('div',[_c('div',{staticClass:"my-10"},[_c('label',{staticClass:"mb-10",attrs:{"for":"email"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("form.email")))])]),_c('a-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email|max:50'),expression:"'required|email|max:50'"}],staticClass:"mt-10",attrs:{"id":"email","type":"email","data-vv-rules":"required","data-vv-as":"Email","data-vv-scope":"formEmail","required":"","name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticStyle:{"color":"red"}},[(
                          _vm.errors.first('formEmail.email') ==
                          'The Email field is required.'
                        )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.enterEmail")))]):(
                          _vm.errors.first('formEmail.email') ==
                          'The Email field must be a valid email.'
                        )?_c('span',[_vm._v(_vm._s(_vm.$t("rules.emailForm")))]):_vm._e()])],1)])])],1)],1):_vm._e(),(_vm.current === 1)?_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{staticClass:"m-10"},[_c('h6',[_vm._v(_vm._s(_vm.$t("form.otp")))])]),_c('a-col',[_c('OtpInput',{attrs:{"isValid":_vm.isCodeValid,"digits":4},on:{"on-complete":_vm.onCompleteHandler},scopedSlots:_vm._u([{key:"errorMessage",fn:function(){return undefined},proxy:true}],null,false,1987425434)})],1)],1):_vm._e(),(_vm.current === 2)?_c('div',[_c('a-row',{staticStyle:{"margin-top":"20px"},attrs:{"gutter":24}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.firstName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'firstname',
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t('rules.require'),
                          } ],
                      } ]),expression:"[\n                      'firstname',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: $t('rules.require'),\n                          },\n                        ],\n                      },\n                    ]"}]})],1)],1),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.lastName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'lastname',
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t('rules.require'),
                          } ],
                      } ]),expression:"[\n                      'lastname',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: $t('rules.require'),\n                          },\n                        ],\n                      },\n                    ]"}]})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.gender.title')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'gender',
                      {
                        rules:
                          [
                            {
                              required: true, message: _vm.$t('rules.require')
                            }
                          ]
                      } ]),expression:"[\n                      'gender',\n                      {\n                        rules:\n                          [\n                            {\n                              required: true, message: $t('rules.require')\n                            }\n                          ]\n                      },\n                    ]"}]},[_c('a-select-option',{attrs:{"value":"MALE"}},[_vm._v(" "+_vm._s(_vm.$t("form.gender.male"))+" ")]),_c('a-select-option',{attrs:{"value":"FEMALE"}},[_vm._v(" "+_vm._s(_vm.$t("form.gender.female"))+" ")]),_c('a-select-option',{attrs:{"value":"other"}},[_vm._v(" "+_vm._s(_vm.$t("form.gender.other"))+" ")])],1)],1)],1),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.birthday')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'dob', { rules: [{ type: 'object', required: true, message: _vm.$t('rules.require') }], }]),expression:"[\n                      'dob', { rules: [{ type: 'object', required: true, message: $t('rules.require') }], }]"}]})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.password'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t('rules.enterPassword'),
                          },
                          {
                            min: 6,
                            message: _vm.$t('rules.passwordLength')
                          }
                        ],
                      } ]),expression:"[\n                      'password',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: $t('rules.enterPassword'),\n                          },\n                          {\n                            min: 6,\n                            message: $t('rules.passwordLength')\n                          }\n                        ],\n                      },\n                    ]"}],attrs:{"type":"password"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.confirmPassword'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'confirm',
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.$t('rules.enterPassword'),
                          },
                          {
                            validator: _vm.compareToFirstPassword,
                          } ],
                      } ]),expression:"[\n                      'confirm',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: $t('rules.enterPassword'),\n                          },\n                          {\n                            validator: compareToFirstPassword,\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1)],1)],1)],1):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"steps-action"},[(_vm.current > 0)?_c('a-button',{staticStyle:{"margin":"5px 0 0 30px"},on:{"click":_vm.prev}},[_vm._v(" "+_vm._s(_vm.$t("button.previous"))+" ")]):_vm._e(),(_vm.current < _vm.steps.length - 1)?_c('a-button',{staticStyle:{"margin":"5px 0 20px 30px"},attrs:{"type":"primary"},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t("button.next"))+" ")]):_vm._e(),(_vm.current == _vm.steps.length - 1)?_c('a-button',{staticStyle:{"margin":"10px"},attrs:{"type":"primary"},on:{"click":_vm.handleRegister}},[_vm._v(" "+_vm._s(_vm.$t("button.submit"))+" ")]):_vm._e()],1),(_vm.message)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v(" "+_vm._s(_vm.$t("form.haveAccount"))+"? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("button.login")))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }